import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import logger from '@helpers/utils/logger/client';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

// This service uses the dayjs library and customizes date and time formatting with translations
// and relative time calculations (like "yesterday", "last month"...)

export class CustomDateService {
  private translation: Record<string, string>;
  private dayjsDate: dayjs.Dayjs;
  private readonly locale: string;

  constructor(
    translation?: Record<string, string>,
    locale?: string,
    private date?: dayjs.ConfigType,
  ) {
    this.translation = translation;
    this.dayjsDate = dayjs(this.date).utc();
    this.locale = 'en';
    // we only use en format but if you want to localize, then import each language like this:
    // import 'dayjs/locale/fr';
  }

  public configureSimilarItemsSoldDate() {
    dayjs.locale(this.locale);
    const config = {
      thresholds: [
        { l: 'd', r: 1 },
        { l: 'dd', r: 29, d: 'day' },
        { l: 'M', r: 1 },
        {
          l: 'MM',
          r: 11,
          d: 'month',
        },
        { l: 'y', r: 1 },
        { l: 'yy', d: 'year' },
      ],
      rounding: Math.floor,
    };
    dayjs.extend(relativeTime, config);
    dayjs.extend(updateLocale);

    dayjs.updateLocale(this.locale, {
      relativeTime: {
        s: this.translation.today,
        ss: this.translation.today,
        m: this.translation.today,
        mm: this.translation.today,
        h: this.translation.today,
        hh: this.translation.today,
        d: (number: number, withoutSuffix: boolean, key: string, isFuture: boolean) => {
          if (!isFuture) {
            return number === 0 ? this.translation.today : this.translation.yesterday;
          }
          return '';
        },
        dd: this.translation.daysAgo,
        M: (number: number, withoutSuffix: boolean, key: string, isFuture: boolean) => {
          if (!isFuture) {
            return this.translation.lastMonth;
          }
          return '';
        },
        MM: this.translation.monthsAgo,
        y: (number: number, withoutSuffix: boolean, key: string, isFuture: boolean) => {
          if (!isFuture) {
            return this.translation.lastYear;
          }
          return '';
        },
        yy: this.translation.yearsAgo,
      },
    });
    return this;
  }

  public diffFormatted(withoutSuffix?: boolean) {
    try {
      return this.dayjsDate.fromNow(withoutSuffix);
    } catch (e) {
      logger.error('CustomDate::', e);
    }
  }
}
