import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import VCImage from '@components/atoms/vc-images/vc-image';
import { CustomDateService } from '@services/custom-date-service';
import clsx from 'clsx';
import { usePreferences } from '@context/preferences.context';
import { SimilarSoldProduct } from '@interfaces/models/price-drop';
import styles from './sold-product-card.module.scss';

interface SoldProductCardProps {
  item: SimilarSoldProduct;
  customClass?: string;
}

export function SoldProductCard({ item, customClass }: SoldProductCardProps) {
  const { t } = useTranslation('common');
  const { language } = usePreferences();

  const dateService = useMemo(() => {
    const translation = {
      yesterday: t('CUSTOM_DATE.YESTERDAY'),
      today: t('CUSTOM_DATE.TODAY'),
      daysAgo: t('CUSTOM_DATE.DAYS_AGO'),
      lastMonth: t('CUSTOM_DATE.LAST_MONTH'),
      monthsAgo: t('CUSTOM_DATE.MONTHS_AGO'),
      lastYear: t('CUSTOM_DATE.LAST_YEAR'),
      yearsAgo: t('CUSTOM_DATE.YEARS_AGO'),
    };
    const service = new CustomDateService(translation, language, item.soldTimestampUTC);
    service.configureSimilarItemsSoldDate();
    return service;
  }, [language, item.soldTimestampUTC]);

  return (
    <ul
      className={clsx(styles.soldProductCard, customClass)}
      key={item.productId}
      data-cy={'similar-sold-product'}
    >
      <li className={styles.soldProductCard__img}>
        <VCImage
          width={232}
          height={232}
          alt={item.title}
          src={`/produit/${item.picture}`}
        />
      </li>
      <li className={styles.soldProductCard__brand}>{item.brand}</li>
      <li className={styles.soldProductCard__desc}>{item.title}</li>
      <li className={styles.soldProductCard__price}>{item.price.formatted}</li>
      <li className={styles.soldProductCard__date}>
        <span>{dateService.diffFormatted(true)}</span>
      </li>
    </ul>
  );
}
