import { User } from '@interfaces/models/user';
import { axios, getAxiosConfig } from '@config/axios';
import { ApiResponse } from '@interfaces/api';
import Environment from '@config/index';
import { Product } from '@interfaces/models/product';
import { UserLikes } from '@interfaces/models/userLikes';

const BASE_URL = `${Environment.apiBaseUrl}/users/me`;

export const getUsersFollowedByCurrentlyAuthenticatedUser = async (): Promise<User['id'][]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: { data },
  } = await axios.get<ApiResponse<User['id'][]>>(`${BASE_URL}/member-follows`, axiosConfig);
  return data;
};

export const getProductsLikedByCurrentlyAuthenticatedUser = async (): Promise<Product['id'][]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: {
      data: { products },
    },
  } = await axios.get<ApiResponse<UserLikes>>(`${BASE_URL}/likes/`, axiosConfig);
  return products ?? [];
};
