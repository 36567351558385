// Retrieves products from query result
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import mapCatalogHitToProduct from '@helpers/utils/catalog/map-catalog-hit-to-product';
import { ElasticSearchFacet, ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import Environment from '@config/index';
import { ListProduct } from '@interfaces/models/types';
import { AvailableCurrencies } from '@interfaces/models/currency';
import { sortAlphabetically } from '@helpers/utils/catalog/catalog-sorting-utils';
import { chainMethods } from '@helpers/utils/general';

const isValidUnixTimestamp = (timestampStr: string): boolean => {
  const timestamp = Number(timestampStr);

  if (!isNaN(timestamp)) {
    const date = new Date(timestamp * 1000);

    if (!isNaN(date.getTime())) {
      return true;
    }
  }

  return false;
};

export const getRecentSearchLastSearchedDate = (): number | null => {
  const searchDateQuery = new URLSearchParams(window.location.search).get('searchDate');
  if (searchDateQuery && isValidUnixTimestamp(searchDateQuery)) {
    return Number(searchDateQuery);
  }
  return null;
};

export const parseProducts = ({
  queryResult,
  country,
  currency,
  language,
  lastSearchedDate = null,
}: {
  queryResult: ElasticSearchResponse;
  country: string;
  currency: AvailableCurrencies;
  language: string;
  lastSearchedDate?: number | null;
}): CatalogProduct[] => {
  // TODO: We should only extract data needed in each product card
  return queryResult.items?.map((data) => mapCatalogHitToProduct(data, country, currency, language, lastSearchedDate));
};

// This fixes bug where a seller-badge without ID appears
// should eventually be fixed in BE
const removeInvalidSellerBadges = (facets: ElasticSearchResponse['facets']['fields']) => {
  if (!facets.sellerBadge) {
    return facets;
  }
  return {
    ...facets,
    sellerBadge: facets.sellerBadge.filter((sellerBadge) => !!sellerBadge.id),
  };
};

const removeDuplicateAndEmptyFacets = (facets: ElasticSearchFacet[] | undefined) =>
  facets
    ? facets
        .filter((material) => !!material.name)
        .sort((a, b) => sortAlphabetically(a.name, b.name))
        .reduce((acc, material) => {
          // remove duplicate ids
          if (!acc.find((e) => e.id === material.id)) {
            acc.push(material);
          }
          return acc;
        }, [] as ElasticSearchFacet[])
    : undefined;

// This fixes DSXP-562, where some material facets don't have name, which causes an error in the UI
// eventually this should be fixed in the backend https://vestiairecollective.atlassian.net/browse/SEAS-1211
const removeInvalidMaterials = (facets: ElasticSearchResponse['facets']['fields']) => {
  if (!facets?.materialLvl0) {
    return facets;
  }
  return {
    ...facets,
    materialLvl0: removeDuplicateAndEmptyFacets(facets.materialLvl0),
    materialLvl1: removeDuplicateAndEmptyFacets(facets.materialLvl1),
  };
};

const removeShowSoldProductsFilter = (
  facets: ElasticSearchResponse['facets']['fields'],
): ElasticSearchResponse['facets']['fields'] => {
  // debugShowSold=1 is for internal use only
  if (!facets?.sold || window.location?.search?.includes('debugShowSold=1')) {
    return facets;
  }
  const { sold, ...rest } = facets;
  const parsedSold = sold.filter((e) => e.id !== '1');
  if (sold.length !== 2 || parsedSold.length === 0) {
    return rest;
  }
  return {
    ...rest,
    sold: parsedSold,
  };
};

const nestMaterialFacets = (facets: ElasticSearchResponse['facets']['fields']) => {
  if (!facets?.materialLvl0) {
    return facets;
  }
  return {
    ...facets,
    materialLvl0: facets.materialLvl0?.map((material) => ({
      ...material,
      children: facets.materialLvl1?.filter((child) => String(child?.parentID) === String(material.id)),
    })),
  };
};

// Facets have some special rules, we'll apply them here, so we don't have to introduce exceptions in components
export const parseFacets = (
  facets: ElasticSearchResponse['facets']['fields'],
  filters: ElasticSearch['filters'],
  isPlpHotfiltersEnabled: boolean,
): ElasticSearchResponse['facets']['fields'] => {
  const facetObject = Object.fromEntries(
    Object.keys(facets || {}).map((facet) => {
      if (facet === 'isOfficialStore' || facet === 'directShippingEligible') {
        const propertyWithIdOf1 = facets[facet].find((e) => e.id === '1');
        const values = [!!propertyWithIdOf1 ? propertyWithIdOf1 : facets[facet].find((e) => e.id === '0')];
        return [facet, values];
      } else {
        return [facet, facets[facet]];
      }
    }),
  ) as ElasticSearchResponse['facets']['fields'];
  const parsedFacets = chainMethods(
    removeShowSoldProductsFilter,
    removeInvalidMaterials,
    removeInvalidSellerBadges,
    nestMaterialFacets,
  )(facetObject);
  if (
    filters &&
    !filters['universe.id'] &&
    parsedFacets &&
    parsedFacets?.universe?.length !== 1 &&
    !isPlpHotfiltersEnabled
  ) {
    // remove category facet if no universe is selected ( if we only have one universe in facets it is shown as selected in the UI)
    const { categoryLvl0, ...rest } = parsedFacets;
    return rest as ElasticSearchResponse['facets']['fields'];
  }
  return parsedFacets;
};

// Requirement here is that we always show current page + 5 next pages (if there are) + last page (if we're not on it),
// where last page is either:
// - 10, if we have more than 10 pages, and we're on page < 5
// - currentPage + (totalPages - currentPage) if we're on page > 5
export const parsePageCount = (currentPage: number, totalPages): number => {
  if (totalPages <= 0) {
    return 1;
  }
  if (totalPages <= 8) {
    return totalPages;
  }
  if (totalPages > 10 && currentPage < 5) {
    return 10;
  }
  const difference = totalPages - currentPage;
  return currentPage + (difference > 6 ? 6 : difference);
};

export const productToListProduct = (products: CatalogProduct[]): ListProduct[] =>
  products.map((product) => ({
    list_products_brand: product.brand?.name ?? '',
    list_products_color: product.colors?.all?.[0]?.name ?? '',
    list_products_id: String(product.id) ?? '',
    list_products_instock: product.inStock ? '1' : '0',
    list_products_name: product.name ?? '',
    list_products_unitprice_ati: String((product.price?.cents ?? 0) / 100 ?? ''),
    list_products_url_page: `${Environment.baseHost}${product.path}`,
    list_products_url_picture: product.pictures[0]?.path
      ? `${Environment.imagesBaseUrl}${product.pictures[0]?.path}`
      : '',
  }));
